import { useTranslation } from 'react-i18next';
import { Typography } from 'app/component-library-wave';
import { ActiveCustomerLocationPage } from '../../../page-wrapper';
import { ContainerFixed } from 'app/components/container';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { voipSettingsTabs } from 'app/utils/navigation-utils';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { ForwardingCard } from '../components/forwarding-card';
import { NumberCard } from '../components/number-card';
import { useLoadVoipSettings } from '../voip-common';
import { FdsAlert } from '@lyse-as/formds-react';
import { RejectionCard } from '../components/rejection-card';
import { VoipSettingsTitle } from '../components/voip-settings-title';
import styles from '../voip-common.module.scss';

export const VoipSettingsPage: ActiveCustomerLocationPage = () => {
  const {
    locations: { data: locationData, fetchStatus: locationFetchStatus },
  } = useAppSelector((state) => state.voipSettings);
  const { t } = useTranslation();
  const { settings } = useLoadVoipSettings();

  if (locationFetchStatus === FetchStatus.REJECTED) {
    return (
      <ContainerFixed isNarrow={true} className={styles.section}>
        <FdsAlert variant="error" role="alert">
          {t('pages.voip.errorLoading')}
        </FdsAlert>
      </ContainerFixed>
    );
  }

  return (
    <div className={styles.container}>
      <ContainerFixed className={styles.tabbedContainer}>
        <VoipSettingsTitle />
        <TabbedContent selectedTabKey={'overview'} tabList={voipSettingsTabs}>
          <ContainerFixed isNarrow={true} className={styles.section}>
            <Typography
              component="h3"
              variant="formds-altibox-subtitle-secondary"
              className={styles.header}
              bold={true}
            >
              {t('pages.voip.overview.myNumbers')}
            </Typography>
            <ul>
              {locationData?.map((location) => (
                <li>
                  <NumberCard location={location} key={location.address} />
                </li>
              ))}
            </ul>
          </ContainerFixed>

          <ContainerFixed isNarrow={true} className={styles.section}>
            <Typography
              component="h3"
              variant="formds-altibox-subtitle-secondary"
              className={styles.header}
              bold={true}
            >
              {t('pages.voip.forwarding.name')}
            </Typography>
            <ul>
              {locationData?.map((location) =>
                location.phoneNumbers?.map((phone) => {
                  return (
                    <li key={phone + location.address + location.postalCode}>
                      <ForwardingCard location={location} phone={phone} settings={settings} />
                    </li>
                  );
                }),
              )}
            </ul>
          </ContainerFixed>

          <ContainerFixed isNarrow={true} className={styles.section}>
            <Typography
              component="h3"
              variant="formds-altibox-subtitle-secondary"
              className={styles.header}
              bold={true}
            >
              {t('pages.voip.rejection.name')}
            </Typography>

            {locationData?.map((location) =>
              location.phoneNumbers?.map((phone) => {
                return (
                  <RejectionCard location={location} settings={settings} phone={phone} key={phone + location.address} />
                );
              }),
            )}
          </ContainerFixed>
        </TabbedContent>
      </ContainerFixed>
    </div>
  );
};
