import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus, VoipBarring, VoipSettings } from 'app/store/root-types';
import { fetchVoipLocations, fetchVoipSettings } from 'app/store/actions/voip-settings-thunks';
import { selectVoipSettings } from 'app/store/actions/voip-settings-actions';

export interface LoadVoipSettings {
  selectedPhoneNumber?: string;
  setSelectedSettings: (phoneNumber: string) => void;
  settings: VoipSettings;
  selectedSettings?: VoipService.VoipSettings | null;
  barring: VoipBarring;
  settingsLoaded: boolean;
  updateFetchStatus: FetchStatus;
}

export const validatePhoneNumber = (phoneNumber: string) => {
  const rule = /^(\+[0-9]{1,3})?([0-9\?])*\*?$/g;
  return rule.test(phoneNumber);
};

export const useLoadVoipSettings = (): LoadVoipSettings => {
  const {
    locations: { data: locationData, fetchStatus },
    settings: { data: voipSettings },
    barring: { data: barring },
    updateSettings: { selectedPhoneNumber, fetchStatus: updateFetchStatus },
  } = useAppSelector((state) => state.voipSettings);

  const settingsLoaded = Object.entries(voipSettings)?.length !== 0;
  const selectedSettings = selectedPhoneNumber ? voipSettings[selectedPhoneNumber].settings : null;

  const dispatch = useAppDispatch();
  const setSelectedSettings = (phoneNumber?: string) => {
    if (!phoneNumber) {
      return;
    }
    dispatch(selectVoipSettings(phoneNumber));
  };

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchVoipLocations());
    }
    if (fetchStatus === FetchStatus.FULFILLED && !settingsLoaded) {
      locationData?.forEach((location) => {
        location.phoneNumbers?.forEach((phonenumber) => {
          dispatch(fetchVoipSettings(phonenumber));
        });
      });
    }
    if (fetchStatus === FetchStatus.FULFILLED && settingsLoaded && !selectedSettings) {
      setSelectedSettings(Object.entries(voipSettings)[0][1].settings.phoneNumber);
    }
  }, [fetchStatus, settingsLoaded, selectedSettings]);

  return {
    settingsLoaded,
    setSelectedSettings,
    settings: voipSettings,
    barring,
    selectedSettings,
    selectedPhoneNumber,
    updateFetchStatus,
  };
};
