import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore } from 'redux-persist';

import { rootReducer } from './root-reducer';
import {
  AuthState,
  ConsentState,
  CoreState,
  DeliveryStatusState,
  StreamingState,
  OperationalMessageState,
  PinState,
  RelocationState,
  SubscriptionState,
  UserContextState,
  EmailState,
  NetworkSettingsState,
  DiscountState,
  ProductState,
  FirewallState,
  ContactDetailsState,
  UsersState,
  MovieRentalHistoryState,
  VoipUsageState,
  EnrichmentState,
  PartnerState,
  ToastMessageState,
  VoipSettingsState,
} from './root-types';
import { sentryReduxEnhancer } from 'app/utils/sentry-utils';

export interface RootState {
  auth: AuthState;
  userContext: UserContextState & PersistPartial;
  operationalMessages: OperationalMessageState;
  core: CoreState;
  deliveryStatus: DeliveryStatusState;
  enrichment: EnrichmentState;
  consent: ConsentState;
  contactDetails: ContactDetailsState;
  pin: PinState;
  subscriptions: SubscriptionState;
  products: ProductState;
  firewall: FirewallState;
  relocation: RelocationState;
  streaming: StreamingState;
  email: EmailState;
  network: NetworkSettingsState;
  discount: DiscountState;
  users: UsersState;
  movieRentalHistory: MovieRentalHistoryState;
  voipUsage: VoipUsageState;
  partner: PartnerState;
  toast: ToastMessageState;
  voipSettings: VoipSettingsState;
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
});

const persistor = persistStore(store);
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export { store, persistor };
