import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from 'app/component-library-wave';
import { ContainerFixed } from 'app/components/container';
import { FdsButton } from '@lyse-as/formds-react';
import { formatVoipNumber } from 'app/utils/string-util';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus, VoipForward, VoipRejection } from 'app/store/root-types';
import { deleteVoipRule, resetVoipUpdateStatus } from 'app/store/actions/voip-settings-actions';
import { updateVoipSettings } from 'app/store/actions/voip-settings-thunks';
import { useEffect } from 'react';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { navigationService } from 'app/service/navigation/navigation-service';
import styles from '../voip-common.module.scss';

export enum VoipRuleType {
  FORWARD,
  REJECT,
}

interface DeleteRule {
  phoneNumber: string;
  ruleAppId: string;
  voipRuleType: VoipRuleType;
}

export const DeleteRuleConfirmation = (props: DeleteRule) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { phoneNumber, ruleAppId, voipRuleType: isForwarding } = props;
  const forwardingRules = useAppSelector((state) => state.voipSettings.settings.data[phoneNumber].settings.forwarding)
    ?.rules as VoipForward[];
  const rejectionRules = useAppSelector(
    (state) => state.voipSettings.settings.data[phoneNumber].settings.incoming?.rejects,
  ) as VoipRejection[];

  const { settings: oldSettings } = useAppSelector((state) => state.voipSettings.settings.data[phoneNumber]);
  const { fetchStatus: updateFetchStatus } = useAppSelector((state) => state.voipSettings.updateSettings);
  const { goToPath } = useAppNavigation();

  const forwardRule = forwardingRules.find((rule) => rule.appGeneratedId === ruleAppId);
  const rejectionRule = rejectionRules.find((rule) => rule.appGeneratedId === ruleAppId);

  const rule = forwardRule || rejectionRule;

  useEffect(() => {
    if (updateFetchStatus === FetchStatus.FULFILLED) {
      dispatch(resetVoipUpdateStatus());
      if (isForwarding) {
        goToPath(navigationService.PAGES.voipSettingsForwarding.url);
        return;
      }
      goToPath(navigationService.PAGES.voipSettingsCalls.url);
    }
  }, [updateFetchStatus]);

  if (!rule) {
    return null;
  }

  const onClick = () => {
    const newRejectionSettings = rejectionRules.filter(
      (rule) => rule.appGeneratedId !== ruleAppId,
    ) as VoipService.Reject[];
    const newForwardSettings = forwardingRules.filter(
      (rule) => rule.appGeneratedId !== ruleAppId,
    ) as VoipService.ForwardRule[];

    const newSettings = {
      ...oldSettings,
      incoming: {
        ...oldSettings.incoming,
        rejects: newRejectionSettings,
      },
      forwarding: {
        ...oldSettings.forwarding,
        rules: newForwardSettings,
      },
    };

    dispatch(updateVoipSettings(newSettings));
  };

  // If rejection rule
  let header = t('pages.voip.rejection.rules.confirmations.delete');
  let description = 'pages.voip.rejection.rules.confirmations.deleteDescription';
  let phone: string | undefined = formatVoipNumber(rejectionRule?.from?.[0]);

  // Overwrite if is forwarding
  if (isForwarding) {
    header = t('pages.voip.forwarding.rules.confirmations.delete');
    description =
      (rule as VoipForward).trigger === 'caller'
        ? 'pages.voip.forwarding.rules.confirmations.deleteDescription'
        : 'pages.voip.forwarding.rules.confirmations.deleteDescriptionAll';
    phone = (rule as VoipForward).callers?.map((phone) => formatVoipNumber(phone)).join(', ');
  }

  const isLoading = updateFetchStatus === FetchStatus.PENDING;

  return (
    <div className={styles.container}>
      <ContainerFixed className={styles.tabbedContainer}>
        <form>
          <ContainerFixed isNarrow={true} className={styles.section}>
            <Typography
              component="h5"
              variant="formds-altibox-subtitle-secondary"
              className={styles.header}
              bold={true}
            >
              {header}
            </Typography>

            <Typography component="p" variant="formds-lead">
              <Trans
                i18nKey={description}
                values={{
                  voipNumber: formatVoipNumber(phoneNumber),
                  phoneNumber: phone,
                }}
              >
                Placeholder
                <Typography component="span" variant="formds-lead" bold={true}>
                  phoneNumber
                </Typography>
              </Trans>
            </Typography>

            <div className={classNames(styles.buttonRow, styles.centered)}>
              <FdsButton variant="secondary" onClick={() => dispatch(deleteVoipRule())}>
                {t('pages.voip.forwarding.cta.cancel')}
              </FdsButton>
              <FdsButton variant={'primary'} onClick={isLoading ? () => {} : onClick} isLoading={isLoading}>
                {t('pages.voip.forwarding.cta.delete')}
              </FdsButton>
            </div>
          </ContainerFixed>
        </form>
      </ContainerFixed>
    </div>
  );
};
