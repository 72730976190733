export const formatDate = (dateTimeString: string): string => {
  const date = dateTimeString.split(' ')[0];
  const time = dateTimeString.split(' ')[1];
  const dateFragments = date.split('/');
  const localDateTime = `${dateFragments[2]}.${dateFragments[1]}.${dateFragments[0]}`;
  return `${time !== undefined ? time : ''} ${localDateTime}`;
};

/*
 * Converts 2021-10-03T10:57:13Z to 10 Oktober
 */
export const iso8601ToDayAndFullMonth = (iso8601String: string): string => {
  const date = new Date(iso8601String);

  // AKW-2803: Make locale dynamic
  return `${date.toLocaleString('no-nb', { day: 'numeric' })} ${date.toLocaleString('no-nb', { month: 'long' })}`;
};

/*
 * Converts 2021-10-03T10:57:13Z to Oktober 2021
 */
export const iso8601ToFullMonthAndYear = (iso8601String: string) => {
  const date = new Date(iso8601String);
  const month = date.toLocaleString('no-nb', { month: 'long' });
  return isValidDate(date) ? `${month.charAt(0).toUpperCase()}${month.slice(1)} ${date.getFullYear()}` : iso8601String;
};

/*
 * Converts 2021-10-03T10:57:13Z to 10. oktober 2021
 */
export const iso8601ToDayFullMonthAndYear = (iso8601String: string) => {
  const date = new Date(iso8601String);
  const day = date.toLocaleString('no-nb', { day: 'numeric' });
  const month = date.toLocaleString('no-nb', { month: 'long' });
  return isValidDate(date) ? `${day} ${month} ${date.getFullYear()}` : iso8601String;
};

/*
 * Converts 2021-10-03T10:57:13Z to 3. oktober 2021, 12:57
 */
export const iso8601ToDayFullMonthYearHourMinute = (iso8601String: string) => {
  const date = new Date(iso8601String);
  // AKW-2803: Make locale dynamic
  return `${date.toLocaleString('no-nb', {
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
  })}`;
};

/*
 * Converts 2021-10-03T10:57:13Z to 03.10.21
 */
export const iso8601ToShortDate = (iso8601String: string) => {
  const date = new Date(iso8601String);
  return `${date.toLocaleString('no-nb', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  })}`;
};

/*
 * Converts 2021-10-03T10:07:13Z to 10:07
 */
export const iso8601ToTime = (iso8601String: string) => {
  const date = new Date(iso8601String);
  return `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
};

export const secondsToDuration = (seconds: number) => new Date(seconds * 1000).toISOString().slice(11, 19);

export const formatDateOrDateRange = (startDateIso8601String?: string, endDateIso8601String?: string) => {
  if (!startDateIso8601String) {
    return null;
  }

  let result;

  if (endDateIso8601String) {
    const startDate = new Date(startDateIso8601String);
    const endDate = new Date(endDateIso8601String);

    result =
      startDate.getMonth() === endDate.getMonth()
        ? `${startDate.getDate()}. - ${iso8601ToDayAndFullMonth(endDateIso8601String)}`
        : `${iso8601ToDayAndFullMonth(startDateIso8601String)} - ${iso8601ToDayAndFullMonth(endDateIso8601String)}`;
  } else {
    result = iso8601ToDayAndFullMonth(startDateIso8601String);
  }

  return result;
};

export const getPreviousXPeriodsFrom = (x?: number, from?: Date): string[] => {
  const now = from ?? new Date();
  const periods = [now.toISOString().substring(0, 7)];
  for (let i = 1; i < (x || 0); i++) {
    const period = new Date(now.setMonth(now.getMonth() - 1));
    periods.push(period.toISOString().substring(0, 7));
  }

  return periods;
};

const isValidDate = (date: unknown) => date instanceof Date && !isNaN(date.getTime());
