import { createAsyncThunk } from '@reduxjs/toolkit';
import { voipService } from 'app/api/service/voip-service';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';

export const fetchVoipLocations = createAsyncThunk('voipSettings/getLocations', async () => voipService.getLocations());
export const fetchVoipSettings = createAsyncThunk('voipSettings/getSettings', async (phoneNumber: string) =>
  voipService.getSettings(phoneNumber),
);
export const fetchBarringSettings = createAsyncThunk('voipSettings/getBarringSettings', async (phoneNumber: string) =>
  voipService.getBarringSettings(phoneNumber),
);

export const updateVoipSettings = createAsyncThunk(
  'voipSettings/update',
  async (settings: VoipService.VoipSettings, thunkApi) =>
    voipService
      .updateSettings(settings)
      .then((settings) => {
        thunkApi.dispatch(
          addToastMessage({
            alertType: 'success',
            heading: i18n.t('features.toast.save.success'),
            role: 'status',
          }),
        );
        return settings;
      })
      .catch((error) => {
        thunkApi.dispatch(
          addToastMessage({
            alertType: 'critical',
            heading: i18n.t('features.toast.save.rejected'),
            role: 'alert',
          }),
        );
        return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
      }),
);

export const updateVoipBarringSettings = createAsyncThunk(
  'voipBarringSettings/update',
  async (settings: VoipService.VoipSelfcareSettings, thunkApi) =>
    voipService
      .updateBarringSettings(settings)
      .then((settings) => {
        thunkApi.dispatch(
          addToastMessage({
            alertType: 'success',
            heading: i18n.t('features.toast.save.success'),
            role: 'status',
          }),
        );
        return settings;
      })
      .catch((error) => {
        thunkApi.dispatch(
          addToastMessage({
            alertType: 'critical',
            heading: i18n.t('features.toast.save.rejected'),
            role: 'alert',
          }),
        );
        return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
      }),
);
