import { Typography } from 'app/component-library-wave';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { Spinner } from 'app/components/spinner';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { BridgeMode } from './network-menu-item/bridge-mode/bridge-mode';
import { Devices } from '../devices/devices';
import { HgwAndTwoBoxIntro } from './hgw-two-box-intro/hgw-and-two-box-intro';
import { WifiPluss } from './wifi-pluss/wifi-pluss';
import { SsId } from './ssid/ssid';
import { useTranslation } from 'react-i18next';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { CombinedBand } from './network-menu-item/combined-band/combined-band';
import { NetworkMenuItem } from './network-menu-item/network-menu-item';
import { useParams } from 'react-router-dom';
import { navigationService } from 'app/service/navigation/navigation-service';
import { CustomerServiceMessage } from './customer-service-message/customer-service-message';

import styles from './wifi-settings.module.scss';

export const WifiSettings: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const { isCsr } = useAppSelector((state) => state.auth);
  const customerId = props.userContext.customerCrmId;
  const customerType = props.userContext.customerType;
  const servicePointId = props.userContext.servicePointId;
  const { goToPath } = useAppNavigation();

  const {
    data: networkData,
    fetchStatus,
    updateWifiSettings: { fetchStatus: updateFetchStatus },
  } = useAppSelector((state) => state.network);

  const isWifiPluss = networkData && networkData?.accessPoints.some((ap) => ap.accessPointType === 'WIFI_PLUSS');
  const noHomeGateway = networkData?.installationType === 'NO_HOMEGATEWAY';

  if (fetchStatus === FetchStatus.PENDING || updateFetchStatus === FetchStatus.PENDING || !networkData) {
    return <Spinner />;
  }

  // If there are managed wifi settings available, only display those settings
  // This avoids confusion between managed wifi and regular home gateway settings
  // E.g. we do not want to display the home gateway at the top for managed wifi,
  // as this is not the device being configured. Managed wifi should be extracted into
  // its own component if users should be able to configure both managed wifi and
  // regular home gateways.
  const managedWifiOfficeSettings =
    networkData?.wifiSettings && networkData.wifiSettings.filter((settings) => settings.type === 'OFFICE')[0];
  const managedWifiGuestSettings =
    networkData?.wifiSettings && networkData.wifiSettings.filter((settings) => settings.type === 'GUEST')[0];
  const displayManagedWifi = managedWifiOfficeSettings || managedWifiGuestSettings;
  const route = useParams();
  const displayManagedWifiGuest = route['*']?.split('/').slice(-1)[0] === 'gjestenett';
  const managedWifiSettings = displayManagedWifiGuest ? managedWifiGuestSettings : managedWifiOfficeSettings;

  const renderDevices = () => {
    if (displayManagedWifiGuest) {
      return null;
    }

    if (noHomeGateway || networkData?.hgw.type) {
      return (
        <Devices
          customerId={customerId}
          customerType={customerType}
          servicePointId={servicePointId}
          networkData={networkData}
        />
      );
    }
    return null;
  };

  const { wifiSettings: wifiIds, regexValidations, fieldValues } = networkData.hgw;

  const RenderManagedWifiSettings = () => {
    if (displayManagedWifi && managedWifiSettings) {
      return (
        <>
          {isCsr && <CustomerServiceMessage displayCannotCombineWifi={false} />}
          <div className={styles.managedWifiContainer}>
            <ul className={styles.managedSsidList}>
              <SsId
                customerId={customerId}
                servicePointId={servicePointId}
                wifiIds={[managedWifiSettings.id]}
                regexValidations={regexValidations}
                uiHideEncryption={true}
                fieldValues={fieldValues}
              />
            </ul>
          </div>
        </>
      );
    }
    return null;
  };

  const RenderManagedWifiGuestLink = () => {
    if (!displayManagedWifi || displayManagedWifiGuest) {
      return null;
    }
    return (
      <NetworkMenuItem
        actionElement={managedWifiGuestSettings?.enabled ? t('pages.network.toggle.on') : t('pages.network.toggle.off')}
        menuText={t('pages.network.managedWifi.guest.heading')}
        onClick={() => goToPath(navigationService.PAGES.enterpriseGuestWifi.url)}
        className={styles.guestNetworkLink}
      />
    );
  };

  return (
    <>
      {
        <div className={styles.container}>
          {!displayManagedWifi && !noHomeGateway && <HgwAndTwoBoxIntro customerType={props.userContext.customerType} />}

          {!noHomeGateway && !networkData.hgw.bridge && (
            <>
              <SsId
                customerId={customerId}
                servicePointId={servicePointId}
                wifiIds={wifiIds}
                regexValidations={regexValidations}
                fieldValues={fieldValues}
                key={Math.random()}
              />
              {!displayManagedWifi && (
                <>
                  <CombinedBand networkData={networkData} wifiIds={networkData.hgw.wifiSettings} />
                  {networkData.wifiSettings.length > 1 && (
                    <NetworkMenuItem
                      actionElement={t('pages.network.advanced.wifi.advanced.subHeader')}
                      menuText={t('pages.network.advanced.wifi.advanced.name')}
                      helpTextHeader={t('pages.network.advanced.wifi.helpTexts.advanced.title')}
                      onClick={() =>
                        goToPath(navigationService.getAdvancedWifiSettingUrl(networkData.hgw.wifiSettings))
                      }
                    >
                      <Typography variant="paragraph3" component="p">
                        {t('pages.network.advanced.wifi.helpTexts.advanced.paragraph1')}
                      </Typography>
                    </NetworkMenuItem>
                  )}
                </>
              )}
            </>
          )}

          {networkData.hgw.canToggleBridge && <BridgeMode canToggleBridge={networkData?.hgw.canToggleBridge} />}
          <div className={styles.subModules}>
            {isWifiPluss && (
              <WifiPluss
                accessPoints={networkData?.accessPoints}
                customerNetwork={networkData}
                servicePointId={servicePointId}
                customerId={customerId}
              />
            )}
            <RenderManagedWifiSettings />
            <RenderManagedWifiGuestLink />
            {renderDevices()}
          </div>
        </div>
      }
    </>
  );
};
