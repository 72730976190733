import { LinkSection } from 'app/features/navigation';
import { CustomerLocationContext, CustomerType, isActiveLocation } from 'app/store/types/user-context-types';

import { Page, FilterType, Service } from './pages/page-types';
import { Pages } from './pages';

class NavigationService {
  // Determine if the link should be visible or not for the logged in user
  public readonly linkShouldRender = (
    page: Page,
    userCrm: string,
    userServices: Service[],
    userPartnerNumber: string,
    customerType: CustomerType,
  ) => {
    // The link requires a specific CRM to be active
    if (page.crm && page.crm !== userCrm) {
      return false;
    }

    // The link requires a specific CustomerType to be active
    if (page.customerType && page.customerType !== customerType) {
      return false;
    }

    // The link requires the user to have this service
    if (
      page.requireOneOfServices &&
      !userServices.some((userService) => page.requireOneOfServices?.includes(userService))
    ) {
      return false;
    }

    // The link requires the user to NOT have this service
    if (
      page.requireNotHavingAnyOfServices &&
      userServices.some((userService) => page.requireNotHavingAnyOfServices?.includes(userService))
    ) {
      return false;
    }

    // The link visibility is determined by either a whitelist or a blacklist provided by the Page data
    if (page.partnerVisibility) {
      const isListed = (page.partnerVisibility.partners as string[])?.includes(userPartnerNumber);
      return page.partnerVisibility.filterType === FilterType.Whitelist ? isListed : !isListed;
    }

    return true;
  };

  public readonly PAGES = Pages;

  public readonly getConcreteStreamingUrl = (portalId: string): string => {
    const url = this.PAGES.streamingService.url;

    return url.replace(':portalId', portalId);
  };

  public readonly getCombineBandUrl = (wifiSettingId: string[]) => {
    const url = this.PAGES.internetSettingsWifiBand.url;
    return url.replace(':wifiSettingId', wifiSettingId[0]);
  };

  public readonly getAdvancedWifiSettingUrl = (wifiSettingId: string[]) => {
    const url = this.PAGES.internetSettingsWifiAdvanced.url;
    return url.replace(':wifiSettingId', wifiSettingId[0]);
  };

  public readonly getEmailPageUrl = (page: Page, account?: MinesiderBackend.EmailAccount) =>
    account ? page.url.replace(':emailAccountId', account.id.toString()) : page.url;

  public readonly getIspDeviceUrl = (deviceId: number): string => {
    const url = this.PAGES.ispDevices.url;

    return url.replace(':deviceId', deviceId.toString());
  };

  public readonly allPages: Page[] = Object.values(this.PAGES);

  public readonly reverseResolvePath = (path: string, pathTemplate: string): string => {
    const urlPartRegex = '[A-Za-z0-9.-_~/]*';
    const templateRegex = `(${urlPartRegex})(:[A-Za-z0-9]+)(${urlPartRegex})`;
    const templateMatches = pathTemplate.match(templateRegex);

    if (!templateMatches) {
      return path;
    }

    const pathVariableRegex = `(${templateMatches[1]})(${urlPartRegex})(${templateMatches[3]})`;
    const pathVariableMatches = path.match(pathVariableRegex);

    return pathVariableMatches && pathVariableMatches[2] && templateMatches[2]
      ? path.replace(pathVariableMatches[2], templateMatches[2])
      : path;
  };
  public readonly getPersonalizedLinkSections = (
    linkSections: LinkSection[],
    customerLocation: CustomerLocationContext,
  ) => {
    const crmSystem = customerLocation ? customerLocation.crmSystem : '';
    const services = customerLocation && isActiveLocation(customerLocation) ? customerLocation.services : [];
    const partnerNumber = customerLocation ? customerLocation.partnerNumber : '';
    const customerType = customerLocation.customerType;

    return linkSections
      .map((section) => {
        const filteredPages = section.pages.filter((page) =>
          this.linkShouldRender(page, crmSystem, services, partnerNumber, customerType),
        );

        return {
          ...section,
          pages: filteredPages,
        };
      })
      .filter((linkSection) => linkSection.pages.length > 0);
  };

  public readonly getEditFirewallRuleUrl = (id: number): string =>
    this.PAGES.firewallUpdateRule.url.replace(':ruleId', id.toString(10));
  public readonly getDeleteUserUrl = (id: string): string => this.PAGES.userDelete.url.replace(':id', id);
}

const navigationService = new NavigationService();

export { navigationService };
