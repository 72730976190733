import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Breakpoint, Button, ButtonType, HeadingLevel, Input, Typography } from 'app/component-library-wave';

import { ErrorMessages } from 'app/features/form/error-messages';
import { uiComponentState } from 'app/features/form/form-utils';
import { useChangeHandler } from 'app/features/form/use-change-handler';
import { useAppDispatch } from 'app/hooks/redux-thunk';
import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { clearNetworkSettingsForm } from 'app/store/actions/network-settings-actions';
import { FetchStatus } from 'app/store/root-types';

import { Card } from 'app/components/card';
import { ButtonsContainer } from 'app/components/container';
import { Helper } from 'app/components/helper/helper';
import { Spinner } from 'app/components/spinner';
import { Checkbox } from 'app/components/checkbox';

import { DhcpCard } from './dhcp-card';
import { IpAddressWithSeparator } from '../../network-common';
import { LanCard } from './lan-card';
import {
  FormModel,
  isDhcpPoolRangeValid,
  isEmpty,
  isFormValid,
  isLastOctetValid,
  isInternalIpThirdOctetValid,
  toFormModel,
  toValueModel,
} from './network-settings-utils';
import { WanCard } from './wan-card';

import styles from './network-settings-form.module.scss';

interface Props {
  initialValues: MinesiderBackend.CustomerNetwork;
  fetchStatus: FetchStatus;
  onSubmit: (customerDetails: MinesiderBackend.UpdateNetwork) => void;
}

export const NetworkSettingsForm: React.FC<Props> = (props) => {
  const { initialValues, fetchStatus, onSubmit } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { goToPath } = useAppNavigation();

  const [form, setForm] = useState<FormModel>(toFormModel(initialValues));
  const errorInvalidLastOctet = t('pages.network.advanced.settings.validation.invalidLastOctetIp');
  const errorInvalidThirdOctetInternalIp = t('pages.network.advanced.settings.validation.invalidThirdOctetIp');
  const errorDhcpFirstGreaterThanLast = t('pages.network.advanced.settings.validation.dhcpFirstGreaterThanLast');
  const errorDmzSameAsIp = t('pages.network.advanced.settings.validation.dmzSameAsIp');
  const errorDmzWithinDhcpRange = t('pages.network.advanced.settings.validation.dmzWithinDhcpRange');
  const errorDhcpFromGreaterThanTo = t('pages.network.advanced.settings.validation.dhcpFirstGreaterThanLast');
  const errorDhcpPoolRange = t('pages.network.advanced.settings.validation.dhcpPoolRange');

  const handleChangeString = useChangeHandler<string, FormModel>(setForm);
  const handleChangeBoolean = useChangeHandler<boolean, FormModel>(setForm);
  const isDhcpOctetValid = isLastOctetValid(form.dhcpLast.value) && isLastOctetValid(form.dhcpFirst.value);

  const getIpAddressArray = useCallback(() => {
    const split = initialValues.networkSettings?.routerSettings?.ipAddress?.split('.') ?? [];
    if (split.length < 4) {
      return [];
    }
    return split.map((i, index) => {
      if (index === 2) {
        return form.lanIpAddressThirdPart.error ? i : form.lanIpAddressThirdPart.value;
      }
      if (index === 3) {
        return form.lanIpAddressLastPart.error ? i : form.lanIpAddressLastPart.value;
      }
      return i;
    });
  }, [initialValues, form.lanIpAddressThirdPart, form.lanIpAddressLastPart]);
  const ipAddressArray = getIpAddressArray();

  const validateLastOctetIp = useCallback(
    (value: string) => {
      if (!isLastOctetValid(value)) {
        return errorInvalidLastOctet;
      }
      if (
        isDhcpOctetValid &&
        Number(value) <= Number(form.dhcpLast.value) &&
        Number(value) >= Number(form.dhcpFirst.value)
      ) {
        return errorDmzWithinDhcpRange;
      }
    },
    [errorInvalidLastOctet, errorDmzWithinDhcpRange, form.dhcpFirst, form.dhcpLast],
  );
  const validateThirdOctetIp = useCallback(
    (value: string) => (isInternalIpThirdOctetValid(value) ? undefined : errorInvalidThirdOctetInternalIp),
    [errorInvalidThirdOctetInternalIp],
  );
  const validateLastOctet = useCallback(
    (value: string) => (isLastOctetValid(value) ? undefined : errorInvalidLastOctet),
    [errorInvalidLastOctet],
  );
  const validateDhcpLast = useCallback(
    (value: string) => {
      if (validateLastOctet(value)) {
        return errorInvalidLastOctet;
      }
      if (Number(form.dhcpFirst.value) > Number(value)) {
        return errorDhcpFirstGreaterThanLast;
      }
      if (isDhcpPoolRangeValid(form.dhcpFirst.value, value)) {
        return errorDhcpPoolRange;
      }
    },
    [form.dhcpFirst, errorDhcpFromGreaterThanTo, errorDhcpPoolRange],
  );
  const validateDmz = useCallback(
    (value: string) => {
      if (isEmpty(value)) {
        return undefined;
      }
      if (validateLastOctet(value)) {
        return errorInvalidLastOctet;
      }
      if (value === form.lanIpAddressLastPart.value) {
        return errorDmzSameAsIp;
      }

      if (
        isDhcpOctetValid &&
        Number(value) <= Number(form.dhcpLast.value) &&
        Number(value) >= Number(form.dhcpFirst.value)
      ) {
        return errorDmzWithinDhcpRange;
      }
    },
    [
      form.lanIpAddressLastPart,
      form.dhcpLast,
      form.dhcpFirst,
      errorInvalidLastOctet,
      errorDmzSameAsIp,
      errorDmzWithinDhcpRange,
    ],
  );

  useEffect(() => {
    setForm((f) => ({ ...f, dmz: { ...f.dmz, error: validateDmz(f.dmz.value) } }));
  }, [validateDmz, setForm]);

  useEffect(() => {
    setForm((f) => ({ ...f, dhcpLast: { ...f.dhcpLast, error: validateDhcpLast(f.dhcpLast.value) } }));
  }, [validateDhcpLast, setForm]);

  const supportsIpv6Changed = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => handleChangeBoolean(e.currentTarget.checked, (f) => ({ ipv6: f })),
    [],
  );
  const upnpChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => handleChangeBoolean(e.currentTarget.checked, (f) => ({ upnp: f })),
    [],
  );
  const dmzChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeString(e.currentTarget.value, (f) => ({ dmz: f }), validateDmz),
    [validateDmz],
  );

  const handleSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const validatedForm: FormModel = {
        bridge: form.bridge,
        lanIpAddressThirdPart: {
          ...form.lanIpAddressThirdPart,
          isTouched: true,
          error: validateThirdOctetIp(form.lanIpAddressThirdPart.value),
        },
        lanIpAddressLastPart: {
          ...form.lanIpAddressLastPart,
          isTouched: true,
          error: validateLastOctetIp(form.lanIpAddressLastPart.value),
        },
        dhcpFirst: {
          ...form.dhcpFirst,
          isTouched: true,
          error: validateLastOctet(form.dhcpFirst.value),
        },
        dhcpLast: {
          ...form.dhcpLast,
          isTouched: true,
          error: validateDhcpLast(form.dhcpLast.value),
        },
        leaseTime: form.leaseTime,
        ipv6: form.ipv6,
        upnp: form.upnp,
        dmz: {
          ...form.dmz,
          isTouched: true,
          error: validateDmz(form.dmz.value),
        },
      };

      setForm(validatedForm);
      if (isFormValid(validatedForm)) {
        onSubmit(toValueModel(validatedForm, initialValues));
      }
    },
    [form, validateLastOctetIp, validateDhcpLast, validateDmz, setForm, onSubmit, validateLastOctet],
  );

  return (
    <form className={styles.formContainer}>
      <WanCard network={initialValues} />
      {initialValues.networkSettings?.routerSettings?.canUpdateRouterSettings && (
        <>
          <LanCard
            network={initialValues}
            form={form}
            handleChangeString={handleChangeString}
            validateLastOctetIp={validateLastOctetIp}
            validateThirdOctetIp={validateThirdOctetIp}
            ipAddressArray={ipAddressArray}
          />
          <DhcpCard
            network={initialValues}
            form={form}
            handleChangeString={handleChangeString}
            validateIp={validateLastOctet}
            validateDhcpLast={validateDhcpLast}
            ipAddressArray={ipAddressArray}
          />
        </>
      )}
      <div className={styles.clusterContainer}>
        {initialValues.networkSettings?.canToggleIpv6 && (
          <Card className={classnames(styles.card, styles.ipv6Card)}>
            <Checkbox
              id="ipv6"
              isChecked={form.ipv6.value}
              onChange={supportsIpv6Changed}
              label={
                <Helper
                  text={t('pages.network.advanced.settings.sections.ipv6RapidDeployment')}
                  textVariant="uiText2"
                  textComponent="h3"
                  heading={t('pages.network.advanced.settings.helpTexts.ipv6RapidDeployment.title')}
                  classNameAlert={styles.checkboxHelperAlert}
                  alertHeadingElement={HeadingLevel.H4}
                >
                  <Typography variant="paragraph3" component="p">
                    {t('pages.network.advanced.settings.helpTexts.ipv6RapidDeployment.content')}
                  </Typography>
                </Helper>
              }
            />
          </Card>
        )}
        {initialValues.networkSettings?.canToggleUpnp && (
          <Card className={classnames(styles.card, styles.upnp)}>
            <Checkbox
              id="ipnp"
              isChecked={form.upnp.value}
              onChange={upnpChanged}
              label={
                <Helper
                  text={t('pages.network.advanced.settings.sections.upnp')}
                  textVariant="uiText2"
                  textComponent="h3"
                  heading={t('pages.network.advanced.settings.helpTexts.upnp.title')}
                  classNameAlert={styles.checkboxHelperAlert}
                  alertHeadingElement={HeadingLevel.H4}
                >
                  <Typography variant="paragraph3" component="p">
                    {t('pages.network.advanced.settings.helpTexts.upnp.content')}
                  </Typography>
                </Helper>
              }
            />
          </Card>
        )}
        {!form.bridge.value && (
          <Card className={styles.card}>
            <Helper
              text={t('pages.network.advanced.settings.sections.dmz.name')}
              textVariant="uiText2"
              textComponent="h3"
              heading={t('pages.network.advanced.settings.helpTexts.dmz.title')}
              alertHeadingElement={HeadingLevel.H4}
            >
              <Typography variant="paragraph3" component="p" className={styles.alertParagraph}>
                {t('pages.network.advanced.settings.helpTexts.dmz.paragraph1')}
              </Typography>
              <Typography variant="paragraph3" component="p">
                <Trans i18nKey="pages.network.advanced.settings.helpTexts.dmz.paragraph2" />
              </Typography>
            </Helper>
            <div className={styles.ipAddress}>
              <IpAddressWithSeparator ipAddressArray={ipAddressArray} numbersToShow={3} />
              <Input
                id="dmz"
                hideLabel={true}
                label={t('pages.network.advanced.settings.sections.dmz.fourthIpAria')}
                value={form.dmz.value}
                onChange={dmzChanged}
                uiComponentState={uiComponentState(form.dmz)}
                maxBreakpoint={Breakpoint.TABLET}
                className={styles.smallInputField}
                data-testid="dmz-input"
                maxLength={3}
              />
            </div>
            <ErrorMessages fields={[form.dmz]} />
          </Card>
        )}
        <ButtonsContainer className={styles.buttons}>
          <div className={styles.buttonRow}>
            {fetchStatus === FetchStatus.PENDING && <Spinner />}
            {fetchStatus !== FetchStatus.PENDING && (
              <>
                <Button
                  buttonType={ButtonType.SECONDARY}
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    goToPath(navigationService.PAGES.internetSettingsOverview.url);
                    dispatch(clearNetworkSettingsForm());
                  }}
                >
                  {t('pages.network.advanced.settings.buttons.cancel')}
                </Button>
                <Button
                  buttonType={ButtonType.PRIMARY_B}
                  type="submit"
                  onClick={handleSubmit}
                  className={styles.saveButton}
                >
                  {t('pages.network.advanced.settings.buttons.save')}
                </Button>
              </>
            )}
          </div>
        </ButtonsContainer>
      </div>
    </form>
  );
};
