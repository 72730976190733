import { config } from 'app/config/config';
import { CancelSource, RestService } from '../common';

class VoipService {
  private readonly restService: RestService;
  private static readonly VOIP_BASE_URL = config.app.voipApiBaseUrl;

  constructor(cancelSource: CancelSource = new CancelSource({ baseUrl: VoipService.VOIP_BASE_URL })) {
    this.restService = cancelSource.service;
  }

  public readonly getLocations = (): Promise<VoipService.VoipLocation[]> =>
    this.restService.get<VoipService.VoipLocation[]>('/locations');

  public readonly getSettings = (phoneNumber: string): Promise<VoipService.VoipSettings> =>
    this.restService.get<VoipService.VoipSettings>(`/settings?phoneNumber=${phoneNumber}`);

  public readonly updateSettings = (settings: VoipService.VoipSettings): Promise<VoipService.VoipSettings> => {
    return this.restService.post<VoipService.VoipSettings>(`/settings/update`, settings);
  };

  public readonly getBarringSettings = (phoneNumber: string): Promise<VoipService.VoipSelfcareSettings> =>
    this.restService.get<VoipService.VoipSelfcareSettings>(`/barring/settings?phoneNumber=${phoneNumber}`);

  public readonly updateBarringSettings = (
    settings: VoipService.VoipSelfcareSettings,
  ): Promise<VoipService.VoipSelfcareSettings> => {
    return this.restService.post<VoipService.VoipSelfcareSettings>(`/barring/settings/update`, settings);
  };
}

const voipService = new VoipService();

export { voipService, VoipService };
