import { HTMLAttributes } from 'react';

import styles from './container.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isNarrow?: boolean;
}

export const ContainerFixed = (props: Props) => {
  const containerWidth = props.isNarrow ? styles.narrow : null;

  const { isNarrow, ...divProps } = props;

  return (
    <div {...divProps} className={`${props.className || ''} ${styles.fixed} ${containerWidth}`}>
      {props.children}
    </div>
  );
};
