import { useTranslation } from 'react-i18next';
import { Typography } from 'app/component-library-wave';
import { ActiveCustomerLocationPage } from '../../../page-wrapper';
import { ContainerFixed } from 'app/components/container';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { useLoadVoipSettings, validatePhoneNumber } from '../voip-common';
import { Card } from 'app/components/card';
import { FdsButton, FdsRadio, FdsRadioItem, FdsTextField } from '@lyse-as/formds-react';
import { navigationService } from 'app/service/navigation/navigation-service';
import { FdsRadioCustomEvent } from '@lyse-as/formds-core/';
import { formatVoipNumber } from 'app/utils/string-util';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'app/hooks/redux-thunk';
import { updateVoipSettings } from 'app/store/actions/voip-settings-thunks';
import { FetchStatus } from 'app/store/root-types';
import { resetVoipUpdateStatus } from 'app/store/actions/voip-settings-actions';
import styles from '../voip-common.module.scss';

type ForwardFrom = 'all' | 'specific';
type Condition = 'always' | 'busy' | 'lost';

export const ForwardingNewRule: ActiveCustomerLocationPage = () => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();
  const dispatch = useAppDispatch();
  const { selectedPhoneNumber: selectedSettings, settings, updateFetchStatus } = useLoadVoipSettings();
  const [forwardFrom, setForwardFrom] = useState<ForwardFrom>('all');
  const [condition, setCondition] = useState<Condition>('always');
  const [toggleRenderFlag, setToggleRenderFlag] = useState(false);
  const toNumberRef = useRef<HTMLFdsTextFieldElement>(null);
  const fromNumberRef = useRef<HTMLFdsTextFieldElement>(null);
  const [submitSettings, setSubmitSettings] = useState(false);

  const isLoading = updateFetchStatus === FetchStatus.PENDING;

  useEffect(() => {
    if (isFromNumberValid && isToNumberValid && submitSettings) {
      submitChanges();
      setSubmitSettings(false);
    }
  }, [submitSettings, toggleRenderFlag]);

  useEffect(() => {
    if (isLoading) {
      dispatch(resetVoipUpdateStatus());
      goToPath(navigationService.PAGES.voipSettingsForwarding.url);
    }
  }, [updateFetchStatus]);

  const isFromNumberValid =
    forwardFrom === 'all' ? true : fromNumberRef.current?.value && validatePhoneNumber(fromNumberRef.current.value);
  const isToNumberValid = toNumberRef.current?.value && validatePhoneNumber(toNumberRef.current.value);

  if (!selectedSettings) {
    return null;
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isLoading) {
      setSubmitSettings((current) => !current);
    }
  };

  const submitChanges = () => {
    const newForwardRule: VoipService.ForwardRule = {
      forwardTo: toNumberRef.current?.value,
      trigger: forwardFrom === 'all' ? 'always' : 'caller',
      ...(fromNumberRef.current?.value && { callers: [fromNumberRef.current.value] }),
    };

    const currentSettings = settings[selectedSettings].settings;

    const newForwardingRules = currentSettings.forwarding?.rules?.concat(newForwardRule);
    const newSettings = {
      ...currentSettings,
      forwarding: {
        ...currentSettings.forwarding,
        rules: newForwardingRules,
      },
    };
    dispatch(updateVoipSettings(newSettings));
  };

  const onFromChange = (e: FdsRadioCustomEvent<string>) => {
    setForwardFrom(e.detail as ForwardFrom);
  };

  const onConditionChange = (e: FdsRadioCustomEvent<string>) => {
    setCondition(e.detail as Condition);
  };

  return (
    <div className={styles.container}>
      <ContainerFixed className={styles.tabbedContainer}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <ContainerFixed isNarrow={true} className={styles.section}>
            <Typography
              component="h5"
              variant="formds-altibox-subtitle-secondary"
              className={styles.header}
              bold={true}
            >
              {t('pages.voip.forwarding.rules.title', { voipNumber: formatVoipNumber(selectedSettings) })}
            </Typography>
            <Card containerClassName={styles.card} className={styles.card}>
              <Typography component="h6" variant="formds-lead" bold={true}>
                {t('pages.voip.forwarding.rules.forwardFrom')}
              </Typography>
              <FdsRadio
                label={t('pages.voip.forwarding.rules.forwardFrom')}
                hideLabel={true}
                vertical={true}
                onFdsChange={(e: FdsRadioCustomEvent<string>) => onFromChange(e)}
                defaultValue={forwardFrom}
              >
                <FdsRadioItem value="all">{t('pages.voip.forwarding.rules.filter.all')}</FdsRadioItem>
                <FdsRadioItem value="specific">{t('pages.voip.forwarding.rules.filter.specific')}</FdsRadioItem>
              </FdsRadio>
              {forwardFrom === 'specific' && (
                <FdsTextField
                  label={t('pages.voip.forwarding.rules.phoneNumber')}
                  name="triggerNumber"
                  required={true}
                  type="tel"
                  ref={fromNumberRef}
                  variant={isFromNumberValid ? 'primary' : 'error'}
                />
              )}
            </Card>
            <Card containerClassName={styles.card} className={styles.card}>
              <Typography component="h6" variant="formds-lead" bold={true}>
                {t('pages.voip.forwarding.rules.forwardCondition')}
              </Typography>
              <FdsRadio
                label={t('pages.voip.forwarding.rules.forwardCondition')}
                hideLabel={true}
                vertical={true}
                onFdsChange={(e: FdsRadioCustomEvent<string>) => onConditionChange(e)}
                defaultValue={condition}
              >
                <FdsRadioItem value="always">{t('pages.voip.forwarding.rules.conditions.always')}</FdsRadioItem>
                <FdsRadioItem value="lost">{t('pages.voip.forwarding.rules.conditions.lost')}</FdsRadioItem>
                <FdsRadioItem value="busy">{t('pages.voip.forwarding.rules.conditions.busy')}</FdsRadioItem>
              </FdsRadio>
            </Card>
            <Card containerClassName={styles.card} className={styles.card}>
              <FdsTextField
                label={t('pages.voip.forwarding.rules.forwardToNumber')}
                name="triggerNumber"
                description={t('pages.voip.forwarding.rules.forwardToNumberDescription')}
                required={true}
                type="tel"
                ref={toNumberRef}
                onBlur={() => setToggleRenderFlag((current) => !current)}
                variant={isToNumberValid ? 'primary' : 'error'}
              />
            </Card>
            <div className={styles.buttonRow}>
              <FdsButton
                variant="secondary"
                onClick={() => goToPath(navigationService.PAGES.voipSettingsForwarding.url)}
              >
                {t('pages.voip.forwarding.cta.cancel')}
              </FdsButton>
              <FdsButton variant="primary" type="submit" isLoading={updateFetchStatus === FetchStatus.PENDING}>
                {t('pages.voip.forwarding.cta.create')}
              </FdsButton>
            </div>
          </ContainerFixed>
        </form>
      </ContainerFixed>
    </div>
  );
};
