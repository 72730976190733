import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Breakpoint, Typography, Button, ButtonType } from 'app/component-library-wave';
import classNames from 'classnames';

import { CustomerLocationContext } from 'app/store/types/user-context-types';
import { LocationSiteName } from 'app/components/location-sitename/location-sitename';
import { changeCustomerLocation } from 'app/store/actions/user-context-actions';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { LocationIcon } from '../location-icon/location-icon';

import styles from './location-picker.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  selectedLocation: CustomerLocationContext;
  addressLabel?: string;
}

const LocationPicker: React.FC<Props> = (props) => {
  const { selectedLocation, addressLabel, className, ...rest } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const customerLocations = useAppSelector((state) => state.userContext.customerLocations);

  if (!selectedLocation || customerLocations.length < 2) {
    return null;
  }

  const onChangeLocation = () => {
    dispatch(changeCustomerLocation());
  };

  return (
    <div className={classNames(styles.container, className)} {...rest}>
      <LocationIcon customerType={selectedLocation.customerType} className={styles.house} role="presentation" />
      <div className={styles.addressWrapper}>
        <Typography className={styles.title} variant="headline5" component="h2" maxBreakpoint={Breakpoint.MOBILE}>
          {addressLabel || t('components.locationPicker.address')}
        </Typography>
        <Typography variant="uiText2" component="p" className={styles.streetaddress} maxBreakpoint={Breakpoint.TABLET}>
          {selectedLocation.address.name || selectedLocation.address.streetaddress}
        </Typography>
        <LocationSiteName location={selectedLocation} />
      </div>
      <Button
        aria-label={t('components.locationPicker.changeButtonAriaLabel')}
        onClick={onChangeLocation}
        className={styles.button}
        buttonType={ButtonType.PRIMARY_B}
      >
        {t('components.locationPicker.change')}
      </Button>
    </div>
  );
};

export { LocationPicker };
