import { useTranslation } from 'react-i18next';
import { Typography } from 'app/component-library-wave';
import { ActiveCustomerLocationPage } from '../../../page-wrapper';
import { ContainerFixed } from 'app/components/container';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { voipSettingsTabs } from 'app/utils/navigation-utils';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { ForwardingCard } from '../components/forwarding-card';
import { useLoadVoipSettings } from '../voip-common';
import { DeleteRuleConfirmation, VoipRuleType } from '../components/delete-rule-confirm';
import { NumberDropDown } from '../components/number-dropdown';
import { VoipSettingsTitle } from '../components/voip-settings-title';
import styles from '../voip-common.module.scss';

export const VoipForwarding: ActiveCustomerLocationPage = () => {
  const {
    locations: { data: locationData },
    updateSettings: { deleteRule },
  } = useAppSelector((state) => state.voipSettings);
  const { t } = useTranslation();
  const { settings, selectedPhoneNumber, setSelectedSettings } = useLoadVoipSettings();

  function selectNumber(phone: string) {
    setSelectedSettings(phone);
  }

  if (!selectedPhoneNumber) {
    return null;
  }

  if (deleteRule !== undefined) {
    return (
      <DeleteRuleConfirmation
        voipRuleType={VoipRuleType.FORWARD}
        phoneNumber={selectedPhoneNumber}
        ruleAppId={deleteRule}
      />
    );
  }

  return (
    <div className={styles.container}>
      <ContainerFixed className={styles.tabbedContainer}>
        <VoipSettingsTitle />

        <TabbedContent selectedTabKey={'forwarding'} tabList={voipSettingsTabs}>
          <ContainerFixed isNarrow={true} className={styles.section}>
            <NumberDropDown
              selectNumber={selectNumber}
              selectedPhoneNumber={selectedPhoneNumber}
              className={styles.chooseNumber}
            />

            {settings[selectedPhoneNumber] && (
              <Typography
                component="h3"
                variant="formds-altibox-subtitle-secondary"
                className={styles.header}
                bold={true}
              >
                {t('pages.voip.forwarding.name')}
              </Typography>
            )}
            {locationData?.map((location) =>
              location.phoneNumbers
                ?.filter((phone) => phone === selectedPhoneNumber)
                .map((phone) => {
                  return (
                    <ForwardingCard
                      isEditable={true}
                      location={location}
                      key={phone}
                      phone={phone}
                      settings={settings}
                    />
                  );
                }),
            )}
          </ContainerFixed>
        </TabbedContent>
      </ContainerFixed>
    </div>
  );
};
